import { render, staticRenderFns } from "./privacy-policy.html?vue&type=template&id=ddae02a0&scoped=true&external"
import script from "./privacy-policy.js?vue&type=script&lang=js&external"
export * from "./privacy-policy.js?vue&type=script&lang=js&external"
import style0 from "./privacy-policy.scss?vue&type=style&index=0&id=ddae02a0&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddae02a0",
  null
  
)

export default component.exports